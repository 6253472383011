import { Icon, SystemProps } from "@storyofams/react-ui";
import React, { useState } from "react";

import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { Button } from "../../../components";
import { Bag, Checkmark, Plus } from "../../../components/common/Icons";
import { useShopify } from "../../../context";
import { ProductByHandleQuery } from "../../../lib/shopify/sdk";

interface ProductSliderProps {
  product?: ProductByHandleQuery["productByHandle"] | any;
  inStockBundle?: boolean;
  isBundle?: boolean;
}

export const AddToCartButton = React.memo(
  ({
    product,
    isBundle = false,
    inStockBundle,
    ...props
  }: ProductSliderProps & SystemProps) => {
    const { addItemToCart, updatingCart, cart } = useShopify();
    const [adding, setAdding] = useState(null);
    const posthog = usePostHog();
    const router = useRouter();
    const inStock = isBundle
      ? !inStockBundle
      : product?.variants_inventory_count > 0 || product?.availableForSale;
    const firstAvailableVariant = isBundle
      ? product.variants.nodes[0].id
      : product?.variants?.edges?.find((item) => item?.node.availableForSale)
          ?.node?.id;
    return (
      <Button
        variant="secondary"
        py="8px !important"
        px="18px !important"
        width={80}
        minHeight={38}
        maxHeight={38}
        mt={2.5}
        {...props}
        hideArrow
        isLoading={updatingCart && adding === product?.id}
        disabled={
          !!cart?.lines?.edges?.find(
            ({ node }) => node.merchandise?.product?.handle === product?.handle
          ) || !inStock
        }
        onClick={() => {
          posthog.capture("Clicked Add to Cart", {
            source: "Product Card",
            product: product.title,
            page: router.pathname,
          });
          setAdding(product?.id);
          addItemToCart(
            [
              {
                quantity: 1,
                merchandiseId:
                  firstAvailableVariant ||
                  Buffer.from(
                    `gid://shopify/ProductVariant/${product.objectID}`
                  ).toString("base64"),
              },
            ],
            () => setAdding(null)
          );
        }}
      >
        {!!cart?.lines?.edges?.find(
          ({ node }) => node.merchandise?.product?.handle === product?.handle
        ) ? (
          <Icon icon={Checkmark} fontSize={2.5} />
        ) : (
          <Icon fontSize={2.5} icon={Plus} />
        )}
        <Icon fontSize={2.5} icon={Bag} ml={0.75} />
      </Button>
    );
  }
);
