import { ReactNode, useMemo } from "react";
import { Box, css, Flex, Stack, SystemProps } from "@storyofams/react-ui";

import { defineMessages, FormattedMessage } from "react-intl";
import { collections } from "~lib";
import { Text } from "../../../components";

const messages = defineMessages({
  discount: "korting",
  new: "nieuw",
  outOfStock: "Out of stock",
});

interface LabelProps {
  type?: "stock" | "discount" | "new";
  children?: ReactNode;
}

const colorByType = {
  discount: "#DBE4DB",
  new: "black",
  stock: "soft",
};

const Label = ({ type, children, ...props }: LabelProps & SystemProps) => (
  <Flex
    bg={colorByType[type]}
    borderRadius="md"
    px={1.5}
    py={0.5}
    variant="center"
    width="max-content"
    {...props}
  >
    <Text
      color={type === "new" && "white"}
      as="span"
      fontSize={1.5}
      lineHeight="medium"
      fontFamily="heading"
      fontWeight={500}
      css={{ textTransform: "uppercase" }}
    >
      {children}
    </Text>
  </Flex>
);

interface LabelsProps {
  inStock?: boolean;
  isNew?: boolean;
  discount?: Number;
  isPP?: boolean;
}

export const Labels = ({
  isNew,
  discount,
  isPP,
  inStock,
  ...props
}: LabelsProps & SystemProps) => {
  return (
    // @ts-ignore
    <Stack space={0.75} {...props}>
      {isNew && (
        <Label
          type="new"
          position={isPP ? "relative" : "absolute"}
          right={isPP ? 0 : 2}
          zIndex={2}
        >
          <FormattedMessage {...messages.new} />
        </Label>
      )}
      {discount && (
        <Label type="discount">
          {discount}% <FormattedMessage {...messages.discount} />
        </Label>
      )}

      {!inStock && (
        <Label type="stock">
          <FormattedMessage {...messages.outOfStock} />
        </Label>
      )}
    </Stack>
  );
};

interface StockLabelProps {
  product?: any;
  inStock?: boolean;
}
interface DiscountLabelProps {
  product?: any;
  isCard?: boolean;
}
const colors = {
  Draagoliën:
    "url(https://a.storyblok.com/f/125270/130x120/01d790fa4c/draagolien.png)",
  "Etherische olie":
    "url(https://a.storyblok.com/f/125270/130x120/790b6d27eb/etherische.png)",
  Hydrosolen:
    "url(https://a.storyblok.com/f/125270/130x120/7c625bdf19/hydrosolen.png)",
  "(Basis) Zepen":
    "url(https://a.storyblok.com/f/125270/130x120/cddf5f0d6d/basis.png)",
  "Klei & Poeders":
    "url(https://a.storyblok.com/f/125270/130x120/ca1d497688/klei.png)",
  "Kruiden & Plantpoeders":
    "url(https://a.storyblok.com/f/125270/130x120/270901c38d/kruiden.png)",
  "Boters & Wassen":
    "url(https://a.storyblok.com/f/125270/130x120/94fefa75ed/boters.png)",
  Hulpstoffen:
    "url(https://a.storyblok.com/f/125270/130x120/5b3cf3c26e/hulpstoffen.png)",
  overall:
    "url(https://a.storyblok.com/f/125270/130x120/5df11c2f3d/default.png)",
};
export const DiscountLabel = ({ product, isCard }: DiscountLabelProps) => {
  const collection = useMemo(() => {
    if (product?.collections?.edges) {
      const collectionItem = collections?.find((item) =>
        product?.collections?.edges?.find(({ node }) =>
          item.toLowerCase().includes(node?.title?.toLowerCase())
        )
      );
      return collectionItem ? colors[collectionItem] : colors.overall;
    } else {
      const collectionItem = collections?.find((item) =>
        product?.collections?.find((collection) =>
          item.toLowerCase().includes(collection?.toLowerCase())
        )
      );
      return collectionItem ? colors[collectionItem] : colors.overall;
    }
  }, [product]);
  const getDiscount = (originalPrice, discountedPrice) =>
    Math.ceil(((originalPrice - discountedPrice) / originalPrice) * 100);
  const discount = useMemo(() => {
    if (product.variants) {
      const discountVariants = product.variants?.edges?.filter(
        ({ node }) => node.compareAtPriceV2 || node.compareAtPrice
      );
      if (discountVariants && discountVariants.length) {
        const discounts = discountVariants.map((item) =>
          item.node.priceV2 && item?.node.compareAtPriceV2
            ? getDiscount(
                Number(item?.node.compareAtPriceV2?.amount),
                Number(item?.node.priceV2?.amount)
              )
            : null
        );
        return discounts.sort((a, b) => b - a)[0];
      } else return null;
    }
    if (product?.meta?.custom?.maximum_discount)
      return product?.meta?.custom?.maximum_discount;
    if (product.compare_at_price) {
      return getDiscount(
        Number(product.compare_at_price),
        Number(product.price)
      );
    } else return null;
  }, [product]);
  if (discount)
    return (
      <Box
        position="absolute"
        width={isCard ? ["78px", "90px"] : ["48px", "67px"]}
        height={isCard ? ["73px", "83px"] : ["43px", "63px"]}
        css={css({
          backgroundImage: `${collection}`,
          backgroundSize: "100% 100%",
        })}
        py={0.5}
        top={[-1.5, -2]}
        left={[-1.5, -2]}
        pl={[1, "12px"]}
        zIndex={2}
        display="flex"
        flexDirection="column"
        pt={"10px"}
      >
        <Text
          width="max-content"
          as="span"
          lineHeight="medium"
          fontFamily="heading"
          fontSize={isCard ? ["27px", "28px"] : ["15px", "20px"]}
          fontWeight={600}
          css={{ textTransform: "uppercase" }}
        >
          -{discount}%
        </Text>
        <Text
          as="span"
          width="max-content"
          fontSize={isCard ? ["15px", "17px"] : [1, 1.5]}
          lineHeight="medium"
          fontFamily="heading"
          fontWeight={600}
          css={{ textTransform: "uppercase" }}
        >
          korting
        </Text>
      </Box>
    );
  return null;
};

export const StockLabel = ({ product, inStock }: StockLabelProps) => {
  if (product?.variants_inventory_count > 0) {
    return null;
  }
  if (product?.availableForSale) {
    return null;
  }

  if (inStock) {
    return null;
  }

  return (
    <Label
      position="absolute"
      top={["initial", 2]}
      right={[1, 2]}
      zIndex={1}
      type="stock"
    >
      <FormattedMessage {...messages.outOfStock} />
    </Label>
  );
};
