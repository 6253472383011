import React from "react";
import { Box, Icon } from "@storyofams/react-ui";
import Image from "next/image";
import { useRouter } from "next/router";
import { usePostHog } from "posthog-js/react";
import { useShopify } from "~context";
import { Star } from "~components/common/Icons";
import {
  Text,
  Button,
  FixedRatio,
  HoverArea,
  ScaleOnHover,
  Heading,
} from "../../../components";
import {
  formatAlgoliaProductsForTracking,
  formatShopifyIdForTracking,
  storeProductClickOrigin,
  track,
} from "../../../lib";
import { CurrencyCode } from "../../../lib/shopify/sdk";
import { shopifyToIntlPrice } from "../../../lib/shopify/utils";
import { AddToCartButton } from "./AddToCartButton";
import { AddToWishlistButton } from "./AddToWishlistButton";
import { DiscountLabel, Labels, StockLabel } from "./Labels";


export const ProductCard = React.memo(
  ({
    small,
    hideStockLabel,
    onClick,
    index,
    fromSearch,
    products,
    isShowRating = true,
    ...product
  }: any) => {
    const formatPrice = (product) => {
      if (product?.priceRange?.minVariantPrice?.amount === "0.0") {
        if (product?.price) {
          return shopifyToIntlPrice({
            currencyCode: CurrencyCode.Eur,
            amount: product.price,
          });
        }
        if (product?.variants?.edges[0]?.node?.priceV2) {
          const variantPrice = product?.variants?.edges[0]?.node?.priceV2;
          return shopifyToIntlPrice(variantPrice);
        }
        // else if (!product?.price) {
        //   const variantPrice = product?.variants?.edges[0]?.node?.priceV2;
        //   return shopifyToIntlPrice(variantPrice);
        // }
      }
      return shopifyToIntlPrice(product?.priceRange?.minVariantPrice);
    };
    const { accessToken } = useShopify();
    const posthog = usePostHog();
    const { pathname } = useRouter();
    const review = product?.meta?.eigenschappen?.product_rating;
    const date1 = new Date(product?.published_at || product?.publishedAt);
    const date2 = new Date();
    const diffDays = Math.ceil(
      Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600 * 24)
    );
    const isNew = diffDays < 90;
    const inStock =
      product?.variants_inventory_count > 0 || product?.availableForSale;
    return (
      <HoverArea>
        <Button
          as="a"
          href={`/products/${product?.handle}`}
          width="100%"
          variant="unstyled"
          onClick={(e) => {
            // posthog.capture("Product card clicked", {
            //   source: pathname,
            //   product: product.title,
            // });
            if (onClick) {
              onClick(e);
            }

            storeProductClickOrigin(
              `${product.id}` || formatShopifyIdForTracking(product.id),
              window.location.pathname
            );
            track({
              event: "dl_select_item",
              ecommerce: {
                currencyCode: "EUR",
                click: {
                  actionField: { list: window.location.pathname },
                  products: product.objectID
                    ? formatAlgoliaProductsForTracking({
                        hits: [product],
                      })
                    : [
                        {
                          name: product.title,
                          id: product.variants?.edges?.[0]?.node?.sku || "",
                          product_id: formatShopifyIdForTracking(product.id),
                          variant_id:
                            formatShopifyIdForTracking(
                              product.variants?.edges?.[0]?.node?.id
                            ) || "",
                          price:
                            product?.priceRange?.minVariantPrice?.amount || "",
                          brand: product.vendor || "",
                          position: Number(index),
                          category: product.productType || "",
                          list: window.location.pathname,
                          variant:
                            formatShopifyIdForTracking(
                              product.variants?.edges?.[0]?.node?.id
                            ) || "",
                        },
                      ],
                },
              },
            });
          }}
        >
          <FixedRatio width="100%" ratio={[[282, 282]]} height={"100%"}>
            <Box
              width="100%"
              height="100%"
              position="relative"
              border="1px"
              borderColor="grey100"
              bg="white"
              p={2}
            >
              <AddToWishlistButton
                product={product}
                border="none"
                bg="unset"
                position="absolute"
                left={0}
                bottom={0}
                zIndex={1}
              />
              <StockLabel product={product} />
              {inStock && <Labels isNew={isNew} inStock={true} />}
              <DiscountLabel product={product} />
              <Box
                position="absolute"
                right={0}
                bottom={1}
                zIndex={1}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {!small && <AddToCartButton border="none" product={product} />}
              </Box>
              <Box position="relative" height="100%">
                {(product?.images?.edges?.[0]?.node?.url ||
                  product?.images?.edges?.[0]?.node?.originalSrc ||
                  product?.images?.edges?.[0]?.node?.image?.originalSrc) && (
                  <ScaleOnHover>
                    <Image
                      layout="fill"
                      objectFit="contain"
                      objectPosition="center"
                      quality={30}
                      loading="lazy"
                      src={
                        product?.metaImage?.value
                          ? product?.metaImage.value
                          : product?.images?.edges?.[0]?.node?.url ||
                            product?.images?.edges?.[0]?.node?.originalSrc ||
                            product?.images?.edges?.[0]?.node?.image
                              ?.originalSrc
                      }
                      alt={
                        product?.images?.edges?.[0]?.node?.altText ||
                        product?.title
                      }
                    />
                  </ScaleOnHover>
                )}
              </Box>
            </Box>
          </FixedRatio>

          <Box mt={[1.75, 2]} flex={1} width="100%" textAlign="left">
            <Heading
              as="h3"
              fontSize={[1.75, small ? 1.75 : 2.25]}
              fontWeight={500}
              lineHeight="medium"
              css={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {product?.title}
            </Heading>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Text
              pt={[0.25, 0.5]}
              color="grey600"
              fontWeight="normal"
              lineHeight={1.5}
              fontSize={[1.75, small ? 1.75 : 2.25]}
              display="inline-flex"
            >
              {product?.priceRange?.minVariantPrice?.amount !==
              product?.priceRange?.maxVariantPrice?.amount
                ? "Vanaf "
                : ""}
              {formatPrice(product).includes("NaN")
                ? product.price
                : formatPrice(product)}
            </Text>
            {((!!review && review !== "0") || product?.metafield?.value) &&
              isShowRating && (
                <Box
                  display={"flex"}
                  fontWeight="bold"
                  alignItems="center"
                  lineHeight={1.5}
                  pt={[0.25, "3px"]}
                >
                  <Text fontSize={[1.75, small ? 1.75 : 2.25]} pl={1}>
                    {review || product.metafield.value}
                  </Text>
                  <Icon
                    ml={0.5}
                    fontSize={[1.5, small ? 1.75 : 2]}
                    color="oldPink"
                    icon={Star}
                    pb={"1px"}
                  />
                </Box>
              )}
          </Box>
        </Button>
      </HoverArea>
    );
  }
);
